<template>
  <v-theme-provider>
    <base-section
      id="the-solution"
      class="grey lighten-4 px-4"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="6"
            sm="10"
          >
            <base-section-heading
              color="transparent"
              title="O método de nutrição que veio pra mudar sua vida:"
            />

            <base-body space="2">
              <span class="text-justify">
                Aqui vou te explicar como a <b>Nutrição Moderna</b> vai te ajudar a acabar de vez por todas com as principais coisas que te impedem de ter resultados reais em:

                <ul>
                  <li>Ganho de massa muscular;</li>
                  <li>Perda de gordura;</li>
                  <li>Saúde!</li>
                </ul>

                <p>Com isso fazer você aprender verdadeiramente a se alimentar através de uma metodologia prática, interativa e eficiente que eu mesmo criei.</p>
              </span>
            </base-body>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="text-center"
          >
            <v-avatar
              size="250"
              rounded
              class="elevation-4"
            >
              <v-img
                src="@/assets/Cassiano2.jpg"
              />
            </v-avatar>
          </v-col>
        </v-row>
        <base-btn-plans />
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionTheSolution',
  }
</script>
